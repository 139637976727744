@primary-color: #1c2c4c; // primary color for all components
@link-color: #1c2c4c; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: #1b1b1b; // heading text color
@text-color: #1b1b1b; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@body-background: #fafafa;
@head-font-family: 'LibreBaskerville';
@head-font-family-bold: 'LibreBaskervilleBold';

@font-face {
    font-family: 'FontsFree-Net-AvenirLTStd-Book';
    src: url('./assets/fonts/FontsFree-Net-AvenirLTStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'InterRegular';
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'InterMedium';
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'InterBold';
    src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontsFree-Net-AvenirLTStd-Heavy';
    src: url('./assets/fonts/FontsFree-Net-AvenirLTStd-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: @head-font-family;
    src: url('./assets/fonts/LibreBaskerville-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: @head-font-family-bold;
    src: url('./assets/fonts/LibreBaskerville-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// general text font
* {
    font-family: 'InterRegular';
}
body {
    min-width: 900px;
}
// heading font
h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: 'FontsFree-Net-AvenirLTStd-Heavy';
    font-family: @head-font-family;
    // font-weight: bold;
    font-weight: normal;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: white;
    border-left: solid 2px #387099;
    color: #28241f;
}
.ant-menu-item-selected path {
    fill: #387099;
}
.sidebar-style {
    background-color: white;
    margin-left: 30px;
}
.sidebar-container {
    margin-left: -50px;
}
body {
    background-color: @body-background;
}
