@import '~antd/dist/antd.less';
@import './theme.less';

* {
    margin: 0;
    padding: 0;
}
.ant-btn-primary {
    background-color: #1c2b4a;
    border-color: #1c2b4a;
}
.ant-btn-primary:hover {
    background-color: #1c2b4a;
    border-color: #1c2b4a;
}
.ant-btn-primary:focus {
    background-color: #1c2b4a;
    border-color: #1c2b4a;
}
.primary-button {
    background-color: #1c2b4a;
    border-color: #1c2b4a;
}
.primary-button:hover {
    background-color: #1c2b4a;
    border-color: #1c2b4a;
}
.secondary-button {
    background-color: white;
    border-color: #1c2b4a;
}
.secondary-button:hover {
    color: #e69942;
    border-color: #1c2b4a;
}
.primary-input {
    border-color: #1c2b4a;
}
.primary-input:hover {
    border-color: #1c2b4a;
}
